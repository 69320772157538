/* eslint-disable no-unused-vars */
'use client';

import { useEffect } from 'react';

import { usePathname } from 'next/navigation';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography/Typography';

import { FlexRowCenter } from 'libs/components/flex-box';

interface Props {
  error: Error & { digest?: string };
  reset: () => void;
}

export default function Error({ error, reset }: Props) {
  const params = usePathname();
  const isRefreshed = sessionStorage.getItem('refreshed');

  useEffect(() => {
    // sendEmail(JSON.stringify({message: error?.message, error: error?.stack, page: params}), 'Page Broken').then(res=>{
    if (window?.clarity) {
      window.clarity('set', 'page_crashed_for_user', 'confirmed');
      window.clarity('set', 'error', error?.message ?? '');
    }

    if (!isRefreshed) {
      sessionStorage.setItem('refreshed', 'true');
      window.location.reload();
    }
    // });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexRowCenter height="60vh">
      <Card sx={{ p: 4, textAlign: 'center' }}>
        <Typography variant="h1" mb={2}>
          Something went wrong!
        </Typography>

        <Button color="error" variant="contained" onClick={() => reset()}>
          Try again
        </Button>
      </Card>
    </FlexRowCenter>
  );
}
